import "../css/footer.css";
import { useTranslation } from "react-i18next";

// https://github.com/akashgiricse/templates-using-bootstrap4/blob/master/Footer/footer.html

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="small bg-light">
      <div className="container py-3 py-sm-5">
        <div className="row">
          <div className="col-12 text-center">
            <h6>{t("quicklinks")}</h6>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a href="/">{t("home")}</a>
              </li>
              {/* <li className="list-inline-item">
                <a href="Forecast">{t("forecast")}</a>
              </li> */}
              <li className="list-inline-item">
                <a href="Replay">{t("replay")}</a>
              </li>
              <li className="list-inline-item">
                <a href="Statistics">{t("statistics")}</a>
              </li>
              <li className="list-inline-item">
                <a href="About">{t("about")}</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <ul className="list-inline">
              <li className="list-inline-item">&copy; 2023 NTE-Skyview</li>
              <li className="list-inline-item">{t("allrightsreserved")}</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
