import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { ApolloProvider } from "@apollo/client/react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TopNavbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/home";
// import Forecast from "./pages/forecast";
import Replay from "./pages/replay";
import Statistics from "./pages/statistics";
import About from "./pages/about";

// Using Apollo client with multiple Graphql endpoints
// https://www.jamalx31.com/tech-posts/using-apollo-with-multiple-graphql-endpoints
// https://www.apollographql.com/docs/react/api/link/introduction/#directional-composition
// https://medium.com/@joanvila/productionizing-apollo-links-4cdc11d278eb
const linkSplitB = new ApolloLink.split(
  (operation) => operation.getContext().clientName === "dump1090",
  new HttpLink({ uri: "/dump1090/graphql" }), // <= apollo will send to this if clientName is "dump1090"
  new HttpLink({ uri: "/dump1090meta/graphql" }) // <= otherwise will send to this
);

const linkSplitA = ApolloLink.split(
  (operation) => operation.getContext().clientName === "flightschedules",
  new HttpLink({ uri: "/flightschedules/graphql" }), // <= apollo will send to this if clientName is "flightschedules"
  linkSplitB // <= otherwise will send to this
);

const client = new ApolloClient({
  link: linkSplitA,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <TopNavbar />
        <Switch>
          {/* <Route path="/forecast" component={Forecast} /> */}
          <Route path="/replay" component={Replay} />
          <Route path="/statistics" component={Statistics} />
          <Route path="/about" component={About} />
          <Route path="*" component={Home} />
        </Switch>
      </Router>
      <Footer />
    </ApolloProvider>
  );
}

export default App;
