import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Title = styled.h4`
    font-family: "Quicksand",sans-serif;
    font-weight: 800;
    color: #036ba1;
    margin-top: 8px;
`;

const Wrapper = styled.div`
    text-align: left;
    background: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 20px;  
`;

const BorderBox = styled.div`
    border: 1px solid #dee2e6;
    border-radius: 5px;
    background: #fff;
    padding: 10px;
    text-align: center;
`;


function StyledBoxWrapper({ component: Component, title, symbol, ...rest }) {
    
    return (
        <Wrapper>
            <Title> <FontAwesomeIcon icon={symbol}/> { title } </Title>
                <BorderBox>
                    <Component {...rest} />
                </BorderBox>
        </Wrapper>
      );
}


export default StyledBoxWrapper