import { useState, useRef, useEffect } from 'react';
import {Container, Row, Col, ButtonGroup, Button} from 'react-bootstrap';
import {gql, useQuery} from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneArrival, faPlaneDeparture, faMap, faClock, faLocationArrow, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import { DateTime, Interval } from 'luxon';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';

import MySpinner from './MySpinner';
import MovementsBarChart from "../components/MovementsBarChart";
import RunwayScatterChart from "../components/RunwayScatterChart";
import EventDetailsTable from "../components/EventDetailsTable";
import FlightPath from "../components/Flightpath";
import PeakHours from "./PeakHours";
import StyledBoxWrapper from '../components/shared/styles';

import "react-datepicker/dist/react-datepicker.css";


const MOVEMENTS_QUERY = gql`
    query movementsQuery($mydate: Date)  {
        takeoffs: takeoffsOnDetails(mydate: $mydate) {
            nodes {
                id
                runway
                time
                callsign
                flightId
                airline
                country
                countryCode
            }
            totalCount
        }
        landings: landingsOnDetails(mydate: $mydate) {
            nodes {
                id
                runway
                time
                callsign
                flightId
                airline
                country
                countryCode
            }
            totalCount
        }
        downtimes: allDowntimes {
            nodes {
                id
                endtime
                starttime
            }
        }
    }
`

//https://usehooks.com/useOnClickOutside/
function useOnClickOutside(refs, handler) {
    useEffect(
      () => {
          
        const listener = (event) => {
          // Event listener for clicks outside of the provided refs
          if ( refs.some((ref) => (!ref.current || ref.current.contains(event.target))) ) {
            // Do nothing if clicking ref's element or descendent elements
            return;
          }
          else {
              handler(event)
          }
        };
        
        document.addEventListener("click", listener);
  
        return () => {
          document.removeEventListener("click", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [refs, handler]
    );
  }

function Movements() {
    const { t, i18n } = useTranslation(['translation', 'replay']);

    const landingsTableRef = useRef(null);
    const takeoffsTableRef = useRef(null);
    const flightPathMapRef = useRef(null);
    
    // https://reactdatepicker.com/
    // Note: DatePicker returns a JSDate, but the component works with Luxon.DateTime
    const [selectedDate, setSelectedDate] = useState( DateTime.utc().setZone("Europe/Paris") );  
    const [selectedLandings, setSelectedLandings] = useState(null)
    const [selectedTakeoffs, setSelectedTakeoffs] = useState(null)
    const [highlightedFlightId, setHighlightedFlightId] = useState(null)
    const [barPlotType, setbarPlotType] = useState('movements')
    
    useOnClickOutside([landingsTableRef, takeoffsTableRef, flightPathMapRef], () => setHighlightedFlightId(null));
    
    
    const { loading, error, data } = useQuery(MOVEMENTS_QUERY, {
        variables: { mydate: selectedDate.toISODate() },
        context: { clientName: 'dump1090' }
    });

    if (loading) return <MySpinner/>
    if (error) {
        console.log("Error in MOVEMENTS_QUERY: " + error.message)
        return <p>{"Error retrieving flight movements data"}</p>
    }

    const landings = data.landings.nodes
    const takeoffs = data.takeoffs.nodes

    // Check if any downtimes occured on the selected date
    const allDownIntervals = data.downtimes.nodes.map(dt => {
        return Interval.fromISO(`${dt.starttime}/${dt.endtime}`)
    })
    const selectedDateInterval = Interval.fromDateTimes(selectedDate.startOf('day'), selectedDate.endOf('day'))
    const downIntervals = allDownIntervals.filter(dti => selectedDateInterval.overlaps(dti))


    function handleBarChartSelection(landings, takeoffs) {
        setSelectedLandings(landings)
        setSelectedTakeoffs(takeoffs)
    }

    function reset_selection() {
        setSelectedLandings(null)
        setSelectedTakeoffs(null)
        setHighlightedFlightId(null)
    }


    function switchPlotType() {
        if (barPlotType==='movements') {
            setbarPlotType('runway')
        }
        else {
            setbarPlotType('movements')
        }
    }

    return (
        <Container> 
            <Row className='justify-content-center'>
                <Col align='center' style={{'marginTop': '20px'}}>
                    <DatePicker
                        todayButton={t('translation:today')}
                        selected={selectedDate.toJSDate()}
                        onChange={(newDate) => {setSelectedDate(DateTime.fromJSDate(newDate)); reset_selection()}}
                    />
                    <h1> { landings.length + takeoffs.length } {t('replay:flights_on')}</h1>
                    <div> {t('translation:landings')}: { landings.length }, {t('translation:takeoffs')}: { takeoffs.length }</div>
                    
                    
                    <ButtonGroup size="sm" style={{"textAlign": "center", "margin": 10}}>
                        <Button variant="primary" onClick={!loading ? switchPlotType : null}>{ barPlotType==='movements' ? t('replay:movements_view') : t('replay:runway_view') }</Button>
                    </ButtonGroup>
                    
                    
                    { barPlotType==='movements' ?
                        <StyledBoxWrapper
                            component={MovementsBarChart}
                            title={t('replay:movements_view')}
                            symbol={faClock}
                            landings = { landings }
                            takeoffs = { takeoffs }
                            downIntervals = {downIntervals}
                            clickHandler = { handleBarChartSelection }
                        /> :
                        <StyledBoxWrapper
                            component={RunwayScatterChart}
                            title={t('replay:runway_view')}
                            symbol={faLocationArrow}
                            landings = { landings }
                            takeoffs = { takeoffs }
                            date = { selectedDate }
                            clickHandler = { handleBarChartSelection }
                        />
                    }
                </Col>
            </Row> 
            <Row className='justify-content-center'>   
                <Col>
                    <StyledBoxWrapper
                        component={PeakHours}
                        title={t('translation:peak_hour')}
                        symbol={faExclamationTriangle}
                        startDate={selectedDate}
                        endDate={selectedDate.plus({ days: 1 })}
                        dt_range= { selectedDate.setLocale(i18n.language).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) }
                    />
                </Col>
            </Row>
            
            <Row>
                {/* NOTE: ref must be attached to Col, as it forwards to a Div node; Will not work on my func components! */}
                <Col xs={12} md={6}>
                    <StyledBoxWrapper
                        component={EventDetailsTable}
                        title={t('translation:landings')}
                        symbol={faPlaneArrival}
                        ref={ landingsTableRef }
                        events={ selectedLandings ? selectedLandings : landings }
                        highlightedFlightId = { highlightedFlightId }
                        setHighlightedFlightId = {setHighlightedFlightId}
                    />
                </Col>
                <Col xs={12} md={6}>
                    <StyledBoxWrapper
                        component={EventDetailsTable}
                        title={t('translation:takeoffs')}
                        symbol={faPlaneDeparture}
                        ref={ takeoffsTableRef } 
                        events={ selectedTakeoffs ? selectedTakeoffs : takeoffs } 
                        highlightedFlightId = { highlightedFlightId }
                        setHighlightedFlightId = {setHighlightedFlightId}
                    />
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col>
                   <StyledBoxWrapper
                        component={FlightPath}
                        title={t('replay:flightmap')}
                        symbol={faMap}
                        ref={ flightPathMapRef }
                        landings={landings}
                        takeoffs={takeoffs}
                        // SelectedLandings/Takeoffs are null upon component render, so pass all landings and takeoffs to plot
                        landings_to_plot = {selectedLandings ? selectedLandings : landings}
                        takeoffs_to_plot = {selectedTakeoffs ? selectedTakeoffs : takeoffs}
                        highlightedFlightId = { highlightedFlightId }
                        setHighlightedFlightId = { setHighlightedFlightId }
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default Movements
