
// https://github.com/lipis/flag-icons/tree/main/flags/1x1
import { useState } from 'react';
// import frFlag from '../assets/fr.svg'
// import ukFlag from '../assets/gb.svg'
import { ReactComponent as FrFlag } from '../assets/fr.svg';
import { ReactComponent as UkFlag } from '../assets/gb.svg';
import { useTranslation } from 'react-i18next';
require('../assets/flags.css')

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(i18n.language)
  
  function handleLocaleChange( newLocale ) {
    i18n.changeLanguage(newLocale)
    setLocale(newLocale)  // Change component state to force rerender of buttons
  }

  return (
    <>
      { locale === 'en-GB'?
        <UkFlag className='flag-icon-round' onClick={ () => handleLocaleChange('fr-FR') }/>
      :
        <FrFlag className='flag-icon-round' onClick={ () => handleLocaleChange('en-GB') }/>
      }
    </>
  )
}


export default LanguageSelector
