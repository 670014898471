import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { gql, useQuery} from '@apollo/client'
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import PeakHours from "./PeakHours";
import MySpinner from './MySpinner';
import StyledBoxWrapper from '../components/shared/styles';


const HISTOGRAM_QUERY = gql`
    query histogramQuery($binType: String, $startDate: Datetime, $endDate: Datetime)  {
        landings: landingsHistogram(bin: $binType, starts: $startDate, ends: $endDate) {
            nodes {
                datetime
                events
            }
        }
        takeoffs: takeoffsHistogram(bin: $binType, starts: $startDate, ends: $endDate) {
            nodes {
                datetime
                events
            }
        }
    }
`

const mode_props = {
    'weekly': {
        'bin_type': 'day', 
        'tickFormatter':   {weekday: 'short'},
        'labelFormatter': DateTime.DATE_MED_WITH_WEEKDAY
    },
    'monthly': {
        'bin_type': 'day',
        'tickFormatter': {month: 'short', day: 'numeric'},
        'labelFormatter': DateTime.DATE_MED_WITH_WEEKDAY
    },
    'yearly': {
        'bin_type': 'week',
        'tickFormatter': {month: 'short', year: 'numeric'},
        'labelFormatter': DateTime.DATE_MED  // 'W'
    },
    'alltime': {
        'bin_type': 'week',
        'tickFormatter': {month: 'short', year: 'numeric'},
        'labelFormatter': DateTime.DATE_MED  // 'W'
    }
}


function EventsHistogram({startDate, endDate, timeMode}) {
    const { t, i18n } = useTranslation(['translation', 'statistics']);

    const { loading, error, data } = useQuery(HISTOGRAM_QUERY, {
        variables: { binType: mode_props[timeMode]['bin_type'], startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' }
    });

    if (loading) return <MySpinner/>
    if (error) {
        console.log("Error in HISTOGRAM_QUERY: " + error.message)
        return <p>{"Error retrieving events histogram data"}</p>
    }
    
    const landings = data.landings.nodes
    const takeoffs = data.takeoffs.nodes

    // Reformat queried data suitable for Recharts plot
    const plot_data = landings.map((l, idx) => {
        return {
            datetime: l.datetime,
            landings: landings[idx].events,
            takeoffs: takeoffs[idx].events
        }
    })

    return (
        <>            
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                    width={500}
                    height={300}
                    data={plot_data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <defs>
                        <linearGradient id="colorLandings" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                        </linearGradient>
                        <linearGradient id="colorTakeoffs" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="datetime" tickFormatter={ t => DateTime.fromISO(t).setZone('Europe/Paris').setLocale(i18n.language).toLocaleString(mode_props[timeMode]['tickFormatter'])} interval="preserveStartEnd" />
                    <YAxis type="number" name="Events" allowDecimals={false}/>
                    <Tooltip labelFormatter={ t => DateTime.fromISO(t).setZone('Europe/Paris').setLocale(i18n.language).toLocaleString(mode_props[timeMode]['labelFormatter'])} />
                    <Legend />
                    <Area dataKey="landings" type="monotone" stackId="a" stroke="#8884d8" fill="url(#colorLandings)" name={t('translation:landings')}/>
                    <Area dataKey="takeoffs" type="monotone" stackId="a" stroke="#82ca9d" fill="url(#colorTakeoffs)" name={t('translation:takeoffs')}/>
                </AreaChart>
            </ResponsiveContainer>
            
            <StyledBoxWrapper
                        component={PeakHours}
                        title={t('translation:peak_hour')}
                        symbol={faExclamationTriangle}
                        startDate={startDate}
                        endDate={endDate}
                        dt_format='LLL dd HH:mm'
            />
        </>
    )
}

export default EventsHistogram
