import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";

import LanguageSelector from "./LanguageSelector";

export default function TopNavbar() {
  const { t } = useTranslation();
  // const changeLanguage = code => {
  //     i18n.changeLanguage(code);
  //   };
  return (
    <>
      <Navbar
        sticky="top"
        bg="light"
        expand="md"
        collapseOnSelect="true"
        md={4}
      >
        <span style={{ display: "inline-block" }}>
          <Navbar.Brand href="/">NTE-Skyview</Navbar.Brand>
          <LanguageSelector />
        </span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <LinkContainer to="/">
              <Nav.Link>{t("home")}</Nav.Link>
            </LinkContainer>
            {/*                     <LinkContainer to="forecast">
                        <Nav.Link>{t('forecast')}</Nav.Link>
                    </LinkContainer> */}
            <LinkContainer to="replay">
              <Nav.Link>{t("replay")}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="statistics">
              <Nav.Link>{t("statistics")}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="about">
              <Nav.Link>{t("about")}</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
