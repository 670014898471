import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateTime, Duration } from 'luxon';
import { useTranslation } from 'react-i18next';


// https://recharts.org/en-US/examples/StackedBarChart
// https://medium.com/swlh/beautiful-visualizations-with-react-and-recharts-76c7c51f926c


// https://stackoverflow.com/questions/46802448/how-do-i-group-items-in-an-array-by-date
function bin_to_hours(events) {
    // Return an object with full hour as keys and array of event objects as values
    const groups = events.reduce((groups, event) => {
        const hour = DateTime.fromISO(event.time).toFormat('HH:00');
        if (!groups[hour]) {
            groups[hour] = [];
        }
        groups[hour].push(event);
        return groups;
    }, {});

    return groups
}


function bin_events(landings, takeoffs, downtimes) {
    // Combine landings and takeoffs objects to one array, filling the gaps with zeros

    const HOURS24 = [
        '00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
        '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
        '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
        '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
    ]

    const binned_landings = bin_to_hours(landings);
    const binned_takeoffs = bin_to_hours(takeoffs);
    const binned_downtimes = bin_to_hours(downtimes);

    const data = HOURS24.map((hour) => {
        return {
            hour,
            landing_events: binned_landings[hour] || [],
            landings: binned_landings[hour] ? binned_landings[hour].length : 0,
            takeoff_events: binned_takeoffs[hour] || [],
            takeoffs: binned_takeoffs[hour] ? binned_takeoffs[hour].length : 0,
            downtimes: binned_downtimes[hour] ? binned_downtimes[hour].length : 0,
        }
    })

    return data
}




function MovementsBarChart({ landings, takeoffs, clickHandler, downIntervals}) {
    const { t } = useTranslation(['translation', 'replay']);

    // Create 1h sub-intervals from down time interval
    const downtimes = downIntervals.map(di =>  {
        //const hoursCount = di.count('hour');
        const hourIntervals = di.splitBy(Duration.fromISO('PT1H'))
        return hourIntervals.map(hi => {return {time: hi.s}});
    })

    const data = bin_events(landings, takeoffs, downtimes.flat());

    
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
            data={data}
            margin={{
                top: 20,
                right: 10,
                left: 0,
                bottom: 5,
            }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hour" interval="preserveStartEnd" />
                <YAxis type="number" allowDecimals={false} label={{ value: t('translation:count') , angle: -90}} />
                <Tooltip />
                <Legend />
                
                { downIntervals.length > 0 ? 
                <Bar dataKey="downtimes"
                    stackId="a"
                    fill="red"
                    name={t('downtime')} >
                    {
                        downIntervals.map((entry, index) => (
                          <Cell key={`cell-${index}`} stroke={'red'}  strokeWidth={index === 2 ? 4 : 1}/>
                        ))
                    }
                </Bar>
                : null }

                <Bar dataKey="landings" 
                    stackId="a" fill="#8884d8" 
                    name={t('landings')} 
                    onClick={(data) => clickHandler(data.landing_events, data.takeoff_events)}
                />

                <Bar dataKey="takeoffs" 
                    stackId="a" fill="#82ca9d"  
                    name={t('takeoffs')} 
                    onClick={(data) => clickHandler(data.landing_events, data.takeoff_events)}
                />


            </BarChart>
        </ResponsiveContainer>
    )
}

export default MovementsBarChart
