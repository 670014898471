import { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneArrival, faPlaneDeparture, faPlane } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

import "../css/RunwayScatterChart.css";


// https://recharts.org/en-US/examples/CustomizedLabelLineChart
class RunwayTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end">
            {payload.value < 0 ? '21' : '03'}
          </text>
        </g>
      );
    }
  }

 
class TakeoffShape extends PureComponent {
    render() {
      const { x, y, stroke } = this.props;
      return (
          // matrix(scaleX(),skewY(),skewX(),scaleY(),translateX(),translateY())
          <g transform={`matrix(0.04, 0, 0, 0.04, ${x-6}, ${y-6})`} >
              <FontAwesomeIcon icon={faPlane} color={stroke} />
          </g>
      )
    }
}
  
class LandingsShape extends PureComponent {
      render() {
        const { x, y, stroke } = this.props;
        // matrix(scaleX(),skewY(),skewX(),scaleY(),translateX(),translateY())
        return (
            <g transform={`matrix(0.04, 0, 0, 0.04, ${x-6}, ${y-6})`} >
                <FontAwesomeIcon icon={faPlane} color={stroke}/>
            </g>
        )
      }
}

const CustomTooltip = ({active, payload, stroke, t}) => {
     if (active && payload && payload.length) {
        const { payload:pl } = payload[0]
        return (
            <div className="custom-tooltip">
                <p>
                    <span>
                        <FontAwesomeIcon icon={pl.event_type==='Landing' ? faPlaneArrival : faPlaneDeparture} color={stroke}/> 
                        &nbsp;{pl.callsign }
                    </span>
                </p>
                <p>{t('translation:time')}: {pl.datetime.toFormat('HH:mm') }</p>
                <p>{t('translation:runway')}: {pl.runway }</p>
            </div>
      )
    }
    else {return null}
}


  /* Plots bar up for runway 21, bar down for runway 03.
  Internally, runway 21 is represented by the value -1; R03 by value +1*/
function RunwayScatterChart({ landings, takeoffs, date}) { 
    const { t } = useTranslation(['translation', 'replay']);
    
    // Reformat data for Recharts plot
    const l_array = landings.map((l, idx) => {
        return {
            datetime: DateTime.fromISO(l.time).setZone('Europe/Paris'),
            runway: landings[idx].runway,
            callsign: landings[idx].callsign,
            event_type: 'Landing'
        }
    })
    const t_array = takeoffs.map((t, idx) => {
        return {
            datetime: DateTime.fromISO(t.time).setZone('Europe/Paris'),
            runway: takeoffs[idx].runway,
            callsign: takeoffs[idx].callsign,
            event_type: 'Takeoff'
        }
    })

    function bin_to_hours(events) {
        // Return an object with full hour as keys and array of event objects as values
        const groups = events.reduce((groups, event) => {
            const hour = event.datetime.startOf('hour');
            if (!groups[hour]) {
                groups[hour] = [];
            }
            groups[hour].push(event);
            return groups;
        }, {});
    
        return groups
    }

    function create_hour_ticks(date) {
        var ticks = [date.startOf('day')]
        for (let i = 0; i < 24; i++) {
            ticks[i+1] = ticks[i].plus({ hours: 1})
        }
        return ticks
    }

    // console.log(create_hour_ticks(date))
    console.log(date.endOf('day'))

    var lt_array = l_array.concat(t_array)

    // https://stackoverflow.com/questions/64801992/how-to-perform-sort-on-array-of-luxon-objects
    lt_array.sort((a, b) => a.datetime < b.datetime ? -1 : a.datetime > b.datetime ? 1 : 0 )

    var groups = bin_to_hours(lt_array)
    
    
    // https://attacomsian.com/blog/javascript-iterate-objects
    // Assign a negative index to runway 21 events, and positive index to runway 03,
    // increasing with time, starting from 0
    let landing_events = []
    let takeoff_events = []
    for (const key in groups) {
        let rw03_idx = 1;
        let rw21_idx = -1;
        groups[key].forEach( (event, idx) => {
            event.idx = event.runway === '03' ? ++rw03_idx : --rw21_idx
            event.event_type === 'Landing' ? landing_events.push(event) : takeoff_events.push(event)
        });
    }



    // Custom SVG in Recharts
    // https://gaurav5430.medium.com/exploring-recharts-using-foreignobject-to-render-custom-html-5c6b75d6207e
    return (
        <ResponsiveContainer width="100%" height={300}>
            <ScatterChart
                width={500}
                height={300}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid horizontal={false} strokeDasharray="1" />
                <XAxis dataKey="datetime"
                    name = 'time'
                    interval="preserveStartEnd"
                    orientation="bottom"
                    type="number"
                    scale="time"
                    axisLine={true}
                    ticks={ create_hour_ticks(date).map(t => t.ts) }
                    domain={ [date.startOf('day').ts, date.plus({ days: 1}).startOf('day').ts] }
                    tickFormatter={ (t) => DateTime.fromMillis(t).toFormat('HH:mm') }
                />
                <YAxis dataKey = 'idx'
                    type="number" 
                    allowDecimals={false} 
                    tickLine={false}
                    label={{ value: t('translation:runway'), angle: -90}}
                    tick={ <RunwayTick /> }
                    ticks={ [-13, +13] }
                />
                <Tooltip content={<CustomTooltip t={t}/>}/>
                <Legend />
                <Scatter name={t('translation:landings')} data={landing_events} fill="#8884d8" stroke="#8884d8" shape={ <LandingsShape /> }/>
                <Scatter name={t('translation:takeoffs')} data={takeoff_events} fill="#82ca9d" stroke="#82ca9d" shape={ <TakeoffShape /> }/>
            </ScatterChart>
        </ResponsiveContainer>
    )

}

export default RunwayScatterChart