import { forwardRef  } from 'react';
import { DateTime } from 'luxon';
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import { StatefulToolTip } from 'react-portal-tooltip'

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../css/table.css';


// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
// https://reactjs.org/docs/forwarding-refs.html
function EventDetailsTable(props, ref) {
    /*
    Forwarding ref to the Div wrapper around BootstrapTable to allow a "click outside" monitoring.
    This func component must be wrapped by forwardRef.
    https://dev.to/sajithpradeep/understanding-the-use-of-useeffect-hook-forwardref-in-react-57jf

    */

    // Destructuring props, as ref is always passed to func components as second argument
    const {events, highlightedFlightId, setHighlightedFlightId} = props

    const { t, i18n } = useTranslation(['translation', 'replay']);

    const tableColumns = [{
        dataField: 'id',
        text: 'Event Id',
        hidden: true
        }, {
        dataField: 'callsign',
        text: t('translation:callsign')
        }, {
        dataField: 'airline',
        text: t('translation:airline'),
        formatter: airlineFormatter,
        sort: true
        }, {
        dataField: 'time',
        text: t('translation:local_time'),
        formatter: timeFormatter,
        sort: true
        }, {
        dataField: 'runway',
        text: t('translation:runway'),
        sort: true
        }
    ];

    const defaultSorted = [{
        dataField: 'time',
        order: 'asc'
      }];

    function timeFormatter(_, row) {
        return (
            DateTime.fromISO(row.time).setZone("CET").setLocale(i18n.language).toLocaleString(DateTime.TIME_SIMPLE)
        );
    }

    function airlineFormatter(_, row) {
        return (
            <span>
                <StatefulToolTip active={true} position="bottom" arrow="center" parent={<ReactCountryFlag countryCode={ row.countryCode }/>}>
                    <div> { row.country } </div>
                </StatefulToolTip> { row.airline }
            </span>
        );
    }

    const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        clickToSelect: true, 
        style: {fontWeight: 'bold',  backgroundColor: '#cfe2f3' },
        selected: [highlightedFlightId],  // The parent component's state determines the highlighted table row
        onSelect: (row) => setHighlightedFlightId(row.flightId),
      };
    
    
    return (
        <div id='bootstrap-table-ref-target' ref={ref}>
            <BootstrapTable
                bootstrap4 
                striped 
                condensed 
                keyField='id' 
                data={ events }
                columns={ tableColumns } 
                selectRow={ selectRow }
                defaultSorted={ defaultSorted }
                noDataIndication={ t('translation:noflights') }
            />
        </div>
    )
};

export default forwardRef(EventDetailsTable)
