import {gql, useQuery} from '@apollo/client';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlaneArrival, faPlaneDeparture} from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';


import MySpinner from './MySpinner';
import '../css/table.css';


const CURFEW_VIOLATIONS_FLIGHTS_QUERY = gql`
query curfewViolationsFlightsQuery($startDate: Datetime, $endDate: Datetime) {
    curfewViolationsOn(start: $startDate, end: $endDate) {
        nodes {
            airline
            callsign
            runway
            time
            tL
        }
  }
}
`


function CurfewViolationsFlightsTable({startDate, endDate, setLoadingFlights}) {
    const { t, i18n } = useTranslation(['translation', 'statistics']);

    const { loading, error, data } = useQuery(CURFEW_VIOLATIONS_FLIGHTS_QUERY, {
        variables: { startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' }
    });

    if (loading) {
        setLoadingFlights(true)
        return <MySpinner/>}
    else {
        setLoadingFlights(false)
    }

    if (error) {
        console.log("Error in CURFEW_VIOLATIONS_FLIGHTS_QUERY: " + error.message)
        return <p>{"Error retrieving curfew violation flights"}</p>
    }

    const violationFlights = data.curfewViolationsOn.nodes

    // Sorting does not work as no unique key is available (callsign is NOT unique)
    const tableColumns = [
        {
            dataField: 'time',
            text: t('translation:date'),
            formatter: dateFormatter,
            csvType: 'date',
            csvFormatter: (_, row) => DateTime.fromISO(row.time).setLocale(i18n.language).toLocaleString()
        }, {
            dataField: 'time',
            text: t('translation:time'),
            formatter: timeFormatter,
            csvType: 'date',
            csvFormatter: timeFormatter
        }, {
            dataField: 'callsign',
            text: t('translation:callsign'),
            hidden: true
        }, {
            dataField: 'airline',
            text: t('translation:airline'),
        }, {
            dataField: 'tL',
            text: t('statistics:tL'),
            formatter: eventSymbolFormatter,
            csvFormatter: (_, row) => row.tL == 'L' ? t('translation:landingAbbr') : t('translation:takeoffAbbr')
        }, {
            dataField: 'runway',
            text: t('translation:runway'),
            csvType : 'number',
        }
    ];

    function dateFormatter(_, row) {
        return (
            DateTime.fromISO(row.time).setLocale(i18n.language).toLocaleString({ month: 'numeric', day: 'numeric',})
        );
    }

    function timeFormatter(_, row) {
        return (
            DateTime.fromISO(row.time).setLocale(i18n.language).toLocaleString(DateTime.TIME_SIMPLE)
        );
    }

    function eventSymbolFormatter(_, row) {
        return (
           row.tL === 'L' ? <FontAwesomeIcon icon={faPlaneArrival} /> : <FontAwesomeIcon icon={faPlaneDeparture} /> 

        );
    }


    const { ExportCSVButton } = CSVExport;

    return (
        // https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/basic-export-csv.html
        <ToolkitProvider
            keyField="callsign"
            data={ violationFlights }
            columns={ tableColumns }
            exportCSV={ {
                fileName: `${t('statistics:curfewviolations')} ${startDate.toLocaleString(DateTime.DATE_SHORT)}-${endDate.toLocaleString(DateTime.DATE_SHORT)}.csv`,
              } }
            >
            {
                props => (
                <div>
                    <hr/>
                    <BootstrapTable
                        bootstrap4
                        striped
                        condensed
                        defaultSorted={[{ dataField: 'time', order: 'asc'}] }
                        noDataIndication={ t('statistics:nocurfewviolations') }
                        { ...props.baseProps }
                    />
                    <ExportCSVButton { ...props.csvProps }>{t('statistics:exportToCsv')}</ExportCSVButton>
                </div>
                )
            }
        </ToolkitProvider>
    )
}

export default CurfewViolationsFlightsTable
