import { gql, useQuery} from '@apollo/client'
import { LayersControl, FeatureGroup, Popup, GeoJSON} from 'react-leaflet'
import { useTranslation } from 'react-i18next';

import "leaflet/dist/leaflet.css";
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


// Feature styles
const ringStyleOptions = { color: '#ca2f3a', "weight": 2, "opacity": 0.3}

const runwaysStyleOptions = { "weight": 2, "opacity": 0.9}

const pgs2019StyleOptions = [
    { color: '#f24453', "weight": 3, "opacity": 0.7, "fillOpacity": 0.5},
    { color: '#f1de2b', "weight": 3, "opacity": 0.7, "fillOpacity": 0.5},
    { color: '#6dae32', "weight": 3, "opacity": 0.7, "fillOpacity": 0.5},
]

// Queries
const RANGE_RINGS_QUERY = gql`
    query range_rings_query {
        rangeRings: allRangeRingsGeojsons {
            nodes {
                radius
                id
                geom
            }
        },
    }
`

const AIRPORT_QUERY = gql`
    query airport_query {
        airport: allAirportsGeojsons(condition: {icao: "LFRS"}) {
            nodes {
                bbox
                altitude
                city
                country
                iata
                icao
                latlon
                locale
                name
                timezone
            }
        },
    }
`

const RUNWAYS_QUERY = gql`
    query runways_query {
        runways: allRunwaysGeojsons {
            nodes {
                name
                geom
                id
                direction
                length
            }
        }
    }
`

const PGS2019_QUERY = gql`
    query pgs2019_query {
        pgs2019: allPgs2019S {
            nodes {
                zone
                geojson
            }
        }
    }
`


function RangeCircles() {
    const { t } = useTranslation('replay');

    const { loading: rc_load, error: rc_err, data: rc_data } = useQuery(RANGE_RINGS_QUERY, {
        context: { clientName: 'dump1090meta' }
    });
    const { loading: a_load, error: a_err, data: a_data } = useQuery(AIRPORT_QUERY, {
        context: { clientName: 'dump1090meta' }
    });

    if (rc_load || a_load) return null

    if (rc_err) {
        console.log("Error in RANGE_RINGS_QUERY: " + rc_err.message)
        return null
    }
    if (a_err) {
        console.log("Error in AIRPORT_QUERY: " + a_err.message)
        return null
    }

    const rings = rc_data.rangeRings.nodes
    const airport = a_data.airport.nodes[0]

    return (
        <LayersControl.Overlay name={t('replay:range_circles')} checked>
            <FeatureGroup pathOptions={ringStyleOptions}>
                    {rings.map((ring, i) => {
                        return <GeoJSON
                                        key={ring.id}
                                        attribution="NTE-Skyview"
                                        data={ ring.geom }>
                                    <Popup>
                                        <div>{t('replay:range')} {ring.radius / 1000} km</div>
                                    </Popup>  
                                </GeoJSON>
                    })}
                    <GeoJSON
                            key={airport.id}
                            data={ airport.latlon }
                            pointToLayer={(feature, latlng) => {return L.circleMarker(latlng, null);}}>
                            <Popup>
                                <h6>{airport.name} ({airport.iata})</h6>
                                <div>{t('replay:altitude')}: { airport.altitude }m</div>
                                <div>{t('replay:coordinates')}: {airport.latlon.coordinates[0]}E, {airport.latlon.coordinates[1]}N</div>
                            </Popup>  
                    </GeoJSON>
            </FeatureGroup>
        </LayersControl.Overlay>
    )
}


function Runways() {
    const { t } = useTranslation();

    const { loading: rw_load, error: rw_err, data: rw_data} = useQuery(RUNWAYS_QUERY, {
        context: { clientName: 'dump1090meta' }
    });

    if (rw_load) return null
    if (rw_err) {
        console.log("Error in RUNWAYS_QUERY: " + rw_err.message)
        return null
    }
    
    const runways = rw_data.runways.nodes

    return (
        <>
            {runways.map(runway => {
                return <LayersControl.Overlay key={runway.id} name={`${t('translation:runway')} ${runway.name}`} >
                    <GeoJSON
                            data={ runway.geom }
                            style={runwaysStyleOptions}>
                            <Popup>
                                <h6>{t('translation:runway')} {runway.name}</h6>
                                <div>{t('translation:direction')}: { runway.direction }°</div>
                                <div>{t('translation:length')}: {runway.length}m</div>
                            </Popup>  
                    </GeoJSON>
                </LayersControl.Overlay>
            })}
        </>
    )
}


function Pgs2019() {
    const { loading, error, data} = useQuery(PGS2019_QUERY, {
        context: { clientName: 'dump1090meta' }
    });

    if (loading) return null
    if (error) {
        console.log("Error in PGS2019_QUERY: " + error.message)
        return null
    }
    
    const polys = data.pgs2019.nodes

    return (
        <LayersControl.Overlay name="PGS 2019">
            <FeatureGroup pathOptions={pgs2019StyleOptions}>
                {polys.map((poly, i) => {
                    return <GeoJSON
                                key={poly.zone}
                                attribution="NTE-Skyview"
                                data={ poly.geojson }
                                style={pgs2019StyleOptions[i]}>
                                <Popup>
                                    <h6>Plan de Gêne Sonore (2019)</h6>
                                    <div><b>Zone:</b> {poly.zone}</div>
                                </Popup>  
                            </GeoJSON>
                })}
            </FeatureGroup>
    </LayersControl.Overlay>
    )
}

export {RangeCircles, Runways, Pgs2019}