import { Container, Card, Button } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation(["translation", "about"]);

  return (
    <Container className="text-center">
      <Card className="text-center">
        <Card.Header>{t("about:disclaimer")}</Card.Header>
        <Card.Body>
          <Card.Text>{t("about:disclaimertext")}</Card.Text>
          {/*           <Button
            as="a"
            variant="outline-primary"
            size="lg"
            href="mailto:info@nte-skyview.fr?subject=NTE-Skyview"
          >
            {t("about:contactdeveloper")}
          </Button> */}
        </Card.Body>
      </Card>

      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            {t("about:datasources")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ul class="list-unstyled">
                <li>
                  {t("about:forecastdata")}:{" "}
                  <a
                    href="https://flightaware.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Flightaware.com
                  </a>
                </li>
                <li>
                  {t("about:pathdata")}{" "}
                  <a
                    href="https://flightaware.com/adsb/flightfeeder/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Flightfeeder
                  </a>
                </li>
                <li>
                  {t("about:mapdata")}:{" "}
                  <a
                    href="https://wiki.openstreetmap.org/wiki/Main_Page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    OpenStreetMap
                  </a>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            {t("about:stack")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <h4>{t("about:backend")}</h4>
              <ul class="list-unstyled">
                <li>
                  {t("about:database")}:{" "}
                  <a
                    href="https://www.postgresql.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PostgreSQL
                  </a>{" "}
                  / <a href="https://postgis.net/">PostGis</a>
                </li>
                <li>
                  {t("about:server")}:{" "}
                  <a
                    href="https://nodejs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NodeJS
                  </a>
                </li>
                <li>
                  {t("about:hosting")}:{" "}
                  <a
                    href="https://heroku.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Heroku.com
                  </a>
                </li>
                <li>
                  {t("about:geoprocessing")}:{" "}
                  <a
                    href="https://qgis.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Qgis
                  </a>
                </li>
                <li>
                  {t("about:wmsserver")}:{" "}
                  <a
                    href="https://docs.qgis.org/3.16/en/docs/server_manual/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Qgis Server
                  </a>
                </li>
              </ul>
              <h4>{t("about:frontend")}</h4>
              <ul class="list-unstyled">
                <li>
                  <a
                    href="https://reactjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    JavaScript / React
                  </a>
                </li>
              </ul>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            {t("about:sources")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              {t("about:dump1090-postgis")}:{" "}
              <a
                href="https://github.com/Xenotech81/dump1090-postgis"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Container>
  );
}

export default About;
