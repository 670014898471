import { Jumbotron, Container, CardDeck, Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
// import schedules from "./flight_schedules.png";
import replay_card from "./replay_card.png";
import stats_card from "./heatmap.png";
import "../css/home.css";

export default function Home() {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid>
        <Jumbotron
          className="text-center"
          fluid
          style={
            {
              //backgroundImage: `url(${bgimage})`,
              //backgroundSize: 'cover',
            }
          }
        >
          <h1>NTE-Skyview</h1>
          <p className="lead">{t("home_subtitle")}</p>
        </Jumbotron>

        <CardDeck>
          {/*           <LinkContainer to="forecast">
            <Card>
              <Card.Img
                variant="top"
                src={schedules}
                alt="Traffic forecast page"
              />
              <Card.Body>
                <Card.Title>{t("forecast")}</Card.Title>
                <Card.Text>{t("forecast_info")}</Card.Text>
              </Card.Body>
            </Card>
          </LinkContainer> */}

          <LinkContainer to="replay">
            <Card>
              <Card.Img variant="top" src={replay_card} />
              <Card.Body>
                <Card.Title>{t("replay")}</Card.Title>
                <Card.Text>{t("replay_info")}</Card.Text>
              </Card.Body>
            </Card>
          </LinkContainer>

          <LinkContainer to="/statistics">
            <Card>
              <Card.Img variant="top" src={stats_card} />
              <Card.Body>
                <Card.Title>{t("statistics")}</Card.Title>
                <Card.Text>{t("statistics_info")}</Card.Text>
              </Card.Body>
            </Card>
          </LinkContainer>
        </CardDeck>
      </Container>
    </>
  );
}
