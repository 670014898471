import {gql, useQuery} from '@apollo/client'
import { DateTime, Duration } from 'luxon';
import Table from 'react-bootstrap/Table'
import { useTranslation } from 'react-i18next';


const PEAK_HOURS_QUERY = gql`
    query PeakHoursQuery($startDate: Datetime, $endDate: Datetime) {
        landings: peakHourLandings(startdate: $startDate, enddate: $endDate) {
            nodes {
                peakHour
                count
            }
        }
        takeoffs: peakHourTakeoffs(startdate: $startDate, enddate: $endDate) {
            nodes {
                peakHour
                count
            }
        }
        all: peakHourAll(startdate: $startDate, enddate: $endDate) {
            nodes {
                peakHour
                count
            }
        }
    }
`

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
var intlOptions = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }


function PeakHours({startDate, endDate, dt_range=null}) {
    const { t, i18n } = useTranslation();

    const { loading, error, data } = useQuery(PEAK_HOURS_QUERY, {
        variables: { startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' }
    });

    if (loading) return <p>Loading...</p>;
    if (error) {
        console.log("Error in PEAK_HOURS_QUERY: " + error.message)
        return <p>{"Error retrieving peak hours data"}</p>
    }

    // BugFix: After midnight, when there have been no flights, landing_peak_hours and takeoff_peak_hours are empty lists!
    // Code crashes at takeoff_peak_hours[0]
    const landing_peak_hours = data.landings.nodes
    const takeoff_peak_hours = data.takeoffs.nodes
    const all_peak_hours = data.all.nodes

    return (
        <Table size="sm" className="peak_hour_table">
            <thead className="thead">
                <tr>
                    <th>{dt_range ? dt_range : startDate.setLocale(i18n.language).toLocaleString(DateTime.DATE_MED).concat(' - ', endDate.setLocale(i18n.language).toLocaleString(DateTime.DATE_MED)) }</th>
                    <th>{t('peak_hour')}</th>
                    <th>{t('count')}</th>
                    <th>{t('period')}</th>
                </tr>
            </thead>
            <tbody  className="tbody">
                { takeoff_peak_hours.length > 0 ?
                    <tr className="tr" key={takeoff_peak_hours[0].peakHour}>
                            <td>{t('takeoffs')}</td>
                            <td>{ DateTime.fromISO(takeoff_peak_hours[0].peakHour).setZone("CET").setLocale(i18n.language).toLocaleString(intlOptions)}</td>
                            <td>{ takeoff_peak_hours[0].count }</td>
                            <td>{ Duration.fromObject({seconds: 3600 / takeoff_peak_hours[0].count}).toFormat("mm:ss") } min</td>
                    </tr> :
                <tr></tr>}
                { landing_peak_hours.length > 0  ?
                    <tr className="tr" key={landing_peak_hours[0].peakHour}>
                        <td>{t('landings')}</td>
                        <td>{ DateTime.fromISO(landing_peak_hours[0].peakHour).setZone("CET").setLocale(i18n.language).toLocaleString(intlOptions)}</td>
                        <td>{ landing_peak_hours[0].count }</td>
                        <td>{ Duration.fromObject({seconds: 3600 / landing_peak_hours[0].count}).toFormat('mm:ss') } min</td>
                    </tr>
                :
                <tr></tr>}
                { all_peak_hours.length > 0 ?
                    <tr className="tr" key={all_peak_hours.peakHour}>
                        <td>{t('both')}</td>
                        <td>{ DateTime.fromISO(all_peak_hours[0].peakHour).setZone("CET").setLocale(i18n.language).toLocaleString(intlOptions)}</td>
                        <td>{ all_peak_hours[0].count }</td>
                        <td>{ Duration.fromObject({seconds: 3600 / all_peak_hours[0].count}).toFormat('mm:ss') } min</td>
                    </tr>
                :
                <tr>{ t('translation:noflights') }</tr>}
            </tbody>
        </Table>
    )
}

export default PeakHours
