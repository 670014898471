import { useState, useEffect, useLayoutEffect, PureComponent } from 'react';
import {gql, useQuery} from '@apollo/client'
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";
import { StatefulToolTip } from 'react-portal-tooltip';
import {Button} from 'react-bootstrap';
import { Treemap, ResponsiveContainer } from 'recharts';

import MySpinner from './MySpinner';
import CurfewViolationsFlightsTable from './CurfewViolationsFlightsTable';

import '../css/table.css';


const CURFEW_VIOLATIONS_AIRLINES_QUERY = gql`
query curfewViolationsAirlinesQuery($startDate: Datetime, $endDate: Datetime) {
    curfewViolationsAirlinesOn(start: $startDate, end: $endDate) {
        nodes {
            airline
            landings
            takeoffs
        }
    }
}
`

const COLORS = ['#8889DD', '#9597E4', '#8DC77B', '#A5D297', '#E2CF45', '#F8C12D'];
const LANDINGS_COLOR = "#8884d8";
const TAKEOFFS_COLOR = "#82ca9d";


// Custom Treemap component
// https://recharts.org/en-US/examples/CustomContentTreemap
class CustomizedContent extends PureComponent {
    render() {
        const { root, depth, x, y, width, height, index, value, colors, children, name } = this.props;

        const violations = depth === 1 ? children.reduce((a,b) => a.value + b.value) : null;
        //const fillColor = depth === 2 && name === "landings" ? "url(#colorLandings)" : "url(#colorTakeoffs)";
        const fillColor = depth === 2 && name === "landings" ? LANDINGS_COLOR : TAKEOFFS_COLOR;
        return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    //fill: depth < 2 ? 'none' : fillColor,
                    fill: depth===1 ? 'none' : fillColor,
                    stroke: '#fff',
                    strokeWidth: 2 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10),
                }}
            />
            {depth === 1 ? (
            <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
                {name !== null ? name : "?"}
            </text>
            ) : null}
            {depth === 2 ? (
            <text x={x + 4} y={y + 18} fill="#fff" fontSize={12} fillOpacity={0.9}>
                {value > 0 ? value : null}
            </text>
            ) : null}
        </g>
      );
    }
  }


function CurfewViolations({startDate, endDate}) {
    const { t } = useTranslation(['translation', 'statistics']);
    
    const [showMore, setShowMore] = useState(false);  // Don't show violating flights
    const [loadingFlights, setLoadingFlights] = useState(false);  // Deactivate button if subComponent is loading

    // Bring the depth2 SVG elements below depth1 in Treemap
    // https://stackoverflow.com/questions/26556436/react-after-render-code
    function reorderSvg() {
        setTimeout(() =>   window.requestAnimationFrame(() => {
            const g = document.getElementsByClassName("recharts-layer recharts-treemap-depth-2")
            for (let item of g) {
                item.parentNode.insertBefore(item, item.parentNode.firstChild);
            }
        }))
    }

    useEffect(() => {
        reorderSvg();
    });

    // Hide detailed flights table if date range has changed to prevent immediate fetch of data
    useEffect(() => {
        setShowMore(false)
    }, [startDate, endDate])

    const { loading, error, data } = useQuery(CURFEW_VIOLATIONS_AIRLINES_QUERY, {
        variables: { startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' },
    });


    if (loading) return <MySpinner/>
    if (error) {
        console.log("Error in CURFEW_VIOLATIONS_AIRLINES_QUERY: " + error.message)
        return <p>{"Error retrieving curfew violations data"}</p>
    }

    const violationAirlines = data.curfewViolationsAirlinesOn.nodes

    const treeMapData = violationAirlines.map(obj => {
        return {
            "name": obj.airline,
            "children": [
                {
                    "name": "landings",
                    "events": parseInt(obj.landings),
                },
                {
                    "name": "takeoffs",
                    "events": parseInt(obj.takeoffs),
                }
            ]
        }
    })


    const tableColumns = [{
        dataField: 'id',
        text: 'Event Id',
        hidden: true
        }, {
        dataField: 'airline',
        text: t('translation:airline'),
        formatter: airlineFormatter,
        sort: true
        }, {
        dataField: 'landings',
        text: t('translation:landings'),
        sort: true
        }, {
        dataField: 'takeoffs',
        text: t('translation:takeoffs'),
        sort: true
        }
    ];

    const defaultSorted = [{
        dataField: 'landings',
        order: 'desc'
      }];

    function airlineFormatter(_, row) {
        return (
            <span>
                <StatefulToolTip active={true} position="bottom" arrow="center" parent={<ReactCountryFlag countryCode={ row.countryCode }/>}>
                    <div> { row.country } </div>
                </StatefulToolTip> { row.airline }
            </span>
        );
    }



    // Loding-state button: https://react-bootstrap.github.io/components/buttons/#button-loading-state
    return (
        <>
            {treeMapData.length > 0 ?
                <ResponsiveContainer width="100%" height={300}>
                <Treemap
                    width={500}
                    height={300}
                    data={treeMapData}
                    dataKey="events"
                    ratio={4 / 3}
                    stroke="#fff"
                    fillOpacity={0.8}
                    content={<CustomizedContent colors={COLORS}/>}
                />
                </ResponsiveContainer>
                : null
            }

            <BootstrapTable
                bootstrap4 
                striped 
                condensed 
                keyField='airline' 
                data={ violationAirlines }
                columns={ tableColumns } 
                defaultSorted={ defaultSorted }
                noDataIndication={ t('statistics:nocurfewviolations') }
            />
            <Button variant="primary"
                size="sm"
                disabled={loadingFlights || violationAirlines.length==0}
                onClick={ !loadingFlights ? () => setShowMore(!showMore) : null }>
                {!showMore || violationAirlines.length===0 ? t('statistics:showFlights') : t('translation:close')}                
            </Button>
            {showMore && violationAirlines.length>0 ? <CurfewViolationsFlightsTable
                                    startDate={startDate}
                                    endDate={endDate}
                                    setLoadingFlights={ setLoadingFlights }
                        />
                : null }
        </>
    )
}

export default CurfewViolations
