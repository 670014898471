import { Spinner } from 'react-bootstrap';

function MySpinner({message=null}) {
    return (
        <div style={{"textAlign": "center"}}>
            <Spinner animation="border" role="status"></Spinner>
            <br />
            { message ? <span className="lead">{message}</span> : <span>Loading...</span> }
        </div>
    )
}

export default MySpinner
