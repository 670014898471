import {gql, useQuery} from '@apollo/client'
import { BarChart, Bar, XAxis, YAxis, ErrorBar, LabelList, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import MySpinner from './MySpinner';


// ErrorBar:
// https://github.com/recharts/recharts/issues/267
// https://jsfiddle.net/coktopus/kfpd0oL5/26/


const WEEKLY_STATS_QUERY = gql`
    query weeklyStats($startDate: Datetime, $endDate: Datetime)  {
        weeklyStats: weeklyStats(from_: $startDate, to_: $endDate) {
            nodes {
                isodow
                takeoffsAvg
                takeoffsStd
                landingsStd
                landingsAvg
            }
        }
    }
`


function WeeklyProfilePlot({ startDate, endDate }) {
    const { t, i18n } = useTranslation(['translation', 'statistics']);

    // Fetch data from GraphQl API
    const { loading, error, data } = useQuery(WEEKLY_STATS_QUERY, {
        variables: { startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' }
    });

    if (loading) return <MySpinner/>
    if (error) {
        console.log("Error in WEEKLY_STATS_QUERY: " + error.message)
        return <p>{"Error retrieving weekly statistics data"}</p>
    }

    // Prepare data for plotting
    const weeklyMovements = data.weeklyStats.nodes;

    let weeklyTakeoffsSum = 0;
    let weeklylandingsSum = 0;
    
    for (let dow in weeklyMovements) {
        weeklyTakeoffsSum += weeklyMovements[dow]['takeoffsAvg'];
        weeklylandingsSum += weeklyMovements[dow]['landingsAvg'];
    }
    
    const dowStats = weeklyMovements.map((dow) => { return {"isodow": dow.isodow,
        "eventsAbs": dow.takeoffsAvg + dow.landingsAvg,
        "eventsRel": (dow.takeoffsAvg + dow.landingsAvg) / (weeklyTakeoffsSum + weeklylandingsSum),
        "std": dow.takeoffsStd + dow.landingsStd,
    }}
    )

    const dowFormatterShort = dow => DateTime.fromObject({"weekday": dow, "locale": i18n.language}).toLocaleString({weekday: 'short'})
    const dowFormatterLong = dow => DateTime.fromObject({"weekday": dow, "locale": i18n.language}).toLocaleString({weekday: 'long'})

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart width={600} height={300} data={dowStats}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <XAxis dataKey="isodow" tickFormatter={ t =>  dowFormatterShort(t)}/>
                <YAxis allowDecimals={false} interval='preserveStartEnd' label={{ value: t('statistics:dailymovements'), angle: -90, position: 'centerBottom' }} />
                <Tooltip labelFormatter={ t => dowFormatterLong(t)} cursor={{fill: 'transparent'}}/>
                <Bar barSize={35} dataKey='eventsAbs' fill="#035aa6" name={t('translation:movements')}>
                    <ErrorBar  dataKey="std" width={4} strokeWidth={1} stroke="#bdbdbd" />
                    <LabelList dataKey="eventsRel" formatter={t => (t).toLocaleString(i18n.language, {style: "percent"})} position="middle" fill="#ffff" fontSize={12} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default WeeklyProfilePlot