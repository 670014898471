import { useState } from 'react';
import {gql, useQuery} from '@apollo/client'
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import MySpinner from './MySpinner';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';


const DAILY_STATS_QUERY = gql`
    query dailyStats($startDate: Datetime, $endDate: Datetime)  {
        dailyStats: dailyStats(from_: $startDate, to_: $endDate) {
            nodes {
                timebin
                takeoffsAvg
                takeoffsStd
                landingsStd
                landingsAvg
            }
        }
    }
`


function DailyProfilePlot({ startDate, endDate }) {
    const { t, i18n } = useTranslation(['translation', 'statistics']);

    const [timeOfDay, setTimeOfDay] = useState('24h');
    
    const timeRange = {'morning':  [0, 12],
        '24h': [0, 24],
        'evening': [12, 24]
    };

    // Fetch data from GraphQl API
    const { loading, error, data } = useQuery(DAILY_STATS_QUERY, {
        variables: { startDate: startDate.toISO(), endDate: endDate.toISO() },
        context: { clientName: 'dump1090' }
    });

    if (loading) return <MySpinner/>
    if (error) {
        console.log("Error in DAILY_STATS_QUERY: " + error.message)
        return <p>{"Error retrieving daily statistics data"}</p>
    }
    
    // Prepare data for plotting
    const dailyMovements = data.dailyStats.nodes;
    var timeRangeMovements = dailyMovements.slice(...timeRange[timeOfDay])

    function tickFormatter(t) {
        return timeOfDay !== '24h' ?
            DateTime.fromObject({"hour": t}).setLocale(i18n.language).toLocaleString(DateTime.TIME_SIMPLE)
        : t
    }

    return (
        <>
            <ToggleButtonGroup size="sm" type="radio" name="timeRange" defaultValue={'24h'} onChange={(value) => setTimeOfDay(value)}>
                <ToggleButton key={1} id={`morning`} value={'morning'} > {t('morning')} </ToggleButton>
                <ToggleButton key={2} id={`24h`} value={'24h'}  >24h</ToggleButton>
                <ToggleButton key={3} id={`evening`} value={'evening'} > {t('evening')} </ToggleButton>
            </ToggleButtonGroup>

            <ResponsiveContainer width="100%" height={400}>
                <RadarChart cx="50%" cy="50%" outerRadius="70%" data={timeRangeMovements}>
                <defs>
                    <radialGradient id="radialColorLandings">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0}/>
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0.6}/>
                    </radialGradient>
                    <radialGradient id="radialColorTakeoffs">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0}/>
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.6}/>
                    </radialGradient>
                </defs>
                <PolarGrid />
                <PolarAngleAxis dataKey="timebin" axisLineType="circle" tickFormatter={t => tickFormatter(t)}/>
                <PolarRadiusAxis angle={30} domain={[0, 9]} />
                <Radar name={t('translation:landings')} dataKey="landingsAvg" stroke="#8884d8" fill="url(#radialColorLandings)" />
                <Radar name={t('translation:takeoffs')} dataKey="takeoffsAvg" stroke="#82ca9d" fill="url(#radialColorTakeoffs)" />
                <Legend />
                </RadarChart>
            </ResponsiveContainer>
        </>
    )
}

export default DailyProfilePlot