/* Dropdown date range selector with optional month picker.

The date ranges depend on the 'time mode': weekly, monthly, yearly, alltime.
For each mode a start_date and end_date is calculated automatically.
The month picker (DatePicker) appears only in monthly mode.
*/

import { DateTime } from 'luxon';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';


// Prepare the date ranges which will be assigned to time modes timeModeProps
const today = DateTime.utc().setZone("Europe/Paris")
const last_monday = today.minus({days: today.weekday-1}).startOf('day')
const next_sunday = last_monday.plus({days: 7}).startOf('day')
const first_of_month = today.minus({days: today.day-1}).startOf('day')
const last_of_month = first_of_month.plus({days: today.daysInMonth}).startOf('day')

// https://vuejsexamples.com/datetime-formatting-parsing-in-vue-using-luxon/
export const timeModeProps = {
    'weekly': {
        'start_date': last_monday, 
        'end_date': next_sunday, 
    },
    'monthly': {
        'start_date': first_of_month, 
        'end_date': last_of_month,
    },
    'yearly': {
        'start_date': today.startOf('year').startOf('day'), 
        'end_date': last_monday.startOf('day'),
    },
    'alltime': {
        'start_date': DateTime.fromISO('2020-03-09').startOf('day'), 
        'end_date': last_monday.startOf('day'),
    }
}


function DateRangeSelector({onTimeModeChange, onStartDateChange, onEndDateChange, timeMode, startDate}) {
    const { t } = useTranslation(['translation', 'statistics']);
    
    // Update state if the user has selected another mode from the dropdown
    function onModeChange(event) {
        const mode = event.target.value
        onTimeModeChange(mode)
        onStartDateChange(timeModeProps[mode]['start_date'])
        onEndDateChange(timeModeProps[mode]['end_date'])
    }

    // Datepickers depending on the time_mode
    let datepicker;
    if (timeMode === 'monthly') { 
        datepicker = <DatePicker
            selected={startDate.toJSDate()}
            minDate={DateTime.fromISO('2020-03-01').toJSDate()}
            maxDate={today.endOf('month').toJSDate()}
            onChange={(newMonth) => {
                onStartDateChange(DateTime.fromJSDate(newMonth).setZone('Europe/Paris'));
                onEndDateChange(DateTime.fromJSDate(newMonth).endOf('month').setZone('Europe/Paris')) }
            }
            dateFormat= "LLLL yyyy"
            showMonthYearPicker
        />
    }
    else {datepicker = null}

    return (
        <>
            <Form.Control as='select' onChange={onModeChange} defaultValue={timeMode}>
                <option value="weekly">{t('statistics:currentweek')}</option>
                <option value="monthly">{t('statistics:currentmonth')}</option>
                <option value="yearly">{t('statistics:currentyear')}</option>
                <option value="alltime">{t('statistics:alltime')}</option>
            </Form.Control>
            
            { datepicker }
        </>
        )
};

export default DateRangeSelector;