import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  faCalendar,
  faBan,
  faChartLine,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import EventsHistogram from "../components/EventsHistogram";
import DateRangeSelector, {
  timeModeProps,
} from "../components/DateRangeSelector";
import WeeklyProfilePlot from "../components/WeeklyProfile";
import DailyProfilePlot from "../components/DailyProfilePlot";
import CurfewViolations from "../components/CurfewViolations";
// import StatsContestCarousel from "../components/Contest";
// import Heatmap from "../components/Heatmap";
import StyledBoxWrapper from "../components/shared/styles";

export default function Statistics() {
  const { t } = useTranslation(["translation", "statistics"]);

  const [timeMode, setTimeMode] = useState("weekly"); // Initial mode to display the histogram in
  const [startDate, setStartDate] = useState(
    timeModeProps["weekly"]["start_date"]
  );
  const [endDate, setEndDate] = useState(timeModeProps["weekly"]["end_date"]);

  return (
    <Container>
      <Row>
        <Col>
          <DateRangeSelector
            onTimeModeChange={setTimeMode}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            timeMode={timeMode}
            startDate={startDate}
          />
          <StyledBoxWrapper
            component={EventsHistogram}
            title={t("statistics:stats")}
            symbol={faChartLine}
            startDate={startDate}
            endDate={endDate}
            timeMode={timeMode}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledBoxWrapper
            component={DailyProfilePlot}
            title={t("statistics:dailyprofileplot")}
            symbol={faClock}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <StyledBoxWrapper
            component={WeeklyProfilePlot}
            title={t("statistics:weeklyprofileplot")}
            symbol={faCalendar}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
      {/*             <Row>
                <Col>
                    <StyledBoxWrapper
                        component={StatsContestCarousel}
                        title={t('statistics:contest')}
                        symbol={faClock}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Col>
            </Row> */}
      <Row>
        <Col>
          <StyledBoxWrapper
            component={CurfewViolations}
            title={t("statistics:curfewviolations")}
            symbol={faBan}
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
      </Row>
      {/* <Row>
                <Col>
                    <StyledBoxWrapper
                        component={Heatmap}
                        title={t('statistics:statmap')}
                        symbol={faMap}
                    />
                </Col>
            </Row> */}
    </Container>
  );
}
