import { forwardRef } from "react";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  FeatureGroup,
  Popup,
  GeoJSON,
} from "react-leaflet";
import { gql, useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneArrival,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

import "../css/flightpath.css";
import MySpinner from "./MySpinner";
import { RangeCircles, Runways, Pgs2019 } from "./Overlays";

// Import leaflet css here or include stylesheet from CDN in index.html
// see https://github.com/PaulLeCam/react-leaflet/issues/453
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const NTE_COORD = [47.155, -1.6];

const FLIGHT_PATHS_QUERY = gql`
  query flightPathsQuery($landingsIds: [BigInt], $takeoffsIds: [BigInt]) {
    landingsGeometry: flightPaths(flightIds: $landingsIds) {
      nodes
    }
    takeoffsGeometry: flightPaths(flightIds: $takeoffsIds) {
      nodes
    }
  }
`;

// Path styling on map
const landingPathStyleDefault = { color: "#8884d8", weight: 2, opacity: 1.0 };
const takeoffPathStyleDefault = { color: "#82ca9d", weight: 2, opacity: 1.0 };
const pathStyleHighlighted = { weight: 4, opacity: 1.0 };
const pathStyleNotHighlighted = { weight: 2, opacity: 0.3 };

function FlightPath(props, ref) {
  const {
    landings,
    takeoffs,
    landings_to_plot,
    takeoffs_to_plot,
    highlightedFlightId,
    setHighlightedFlightId,
  } = props;

  const landings_ids = landings.map((l) => {
    return l.flightId;
  });
  const takeoffs_ids = takeoffs.map((t) => {
    return t.flightId;
  });

  const { t } = useTranslation(["translation", "replay"]);

  const { loading, error, data } = useQuery(FLIGHT_PATHS_QUERY, {
    variables: { landingsIds: landings_ids, takeoffsIds: takeoffs_ids },
    context: { clientName: "dump1090" },
  });

  if (loading) return <MySpinner message={t("replay:loading_map")} />;
  if (error) {
    console.log("Error in FLIGHT_PATHS_QUERY: " + error.message);
    return <p>"Error retrieving flight path"</p>;
  }

  // Construct GeoJsonObjects from path geometry returned by query
  const landingsGeoJson = data.landingsGeometry.nodes.map((geom, idx) => {
    return {
      type: "Feature",
      properties: { flightId: landings_ids[idx] },
      geometry: geom,
    };
  });

  const takeoffsGeoJson = data.takeoffsGeometry.nodes.map((geom, idx) => {
    return {
      type: "Feature",
      properties: { flightId: takeoffs_ids[idx] },
      geometry: geom,
    };
  });

  function handleMouseClick(e) {
    setHighlightedFlightId(e.sourceTarget.feature.properties.flightId);
  }

  // If no path is highlighted (highlightedFlightId=null), plot all paths with default style.
  // If a flight is highlighted, plot it thicker, but switch all other visible paths to a lighter style.
  function chosePathStyle(defaultStyle, flightId) {
    if (highlightedFlightId) {
      return flightId === highlightedFlightId
        ? { ...defaultStyle, ...pathStyleHighlighted }
        : pathStyleNotHighlighted;
    } else {
      return defaultStyle;
    }
  }

  return (
    <div id="mapcontainter-ref-target" ref={ref}>
      <MapContainer
        center={NTE_COORD}
        zoom={11}
        scrollWheelZoom={false}
        eventHandlers={{
          click: (event) => alert("Boo"),
        }}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="OpenStreetMap">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>

          <LayersControl.BaseLayer checked name="OpenMapTiles">
            <TileLayer
              attribution='&copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>'
              url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
            />
          </LayersControl.BaseLayer>

          <LayersControl.Overlay name={t("translation:landings")} checked>
            <FeatureGroup pathOptions={landingPathStyleDefault}>
              {landingsGeoJson.map((feature, i) => {
                const landing = landings[i];

                if (
                  landings_to_plot.map((etp) => etp.id).includes(landing.id)
                ) {
                  return (
                    <GeoJSON
                      key={landing.id.toString()}
                      attribution="NTE-Skyview"
                      data={feature}
                      style={() =>
                        chosePathStyle(
                          landingPathStyleDefault,
                          landing.flightId
                        )
                      }
                      eventHandlers={{
                        click: handleMouseClick,
                      }}
                    >
                      <Popup>
                        <h3>
                          {<FontAwesomeIcon icon={faPlaneArrival} />}{" "}
                          {landing.callsign}{" "}
                        </h3>
                        <h6>
                          <ReactCountryFlag countryCode={landing.countryCode} />{" "}
                          {landing.airline}
                        </h6>
                        <div>
                          {t("replay:landing_time")}:{" "}
                          {DateTime.fromISO(landing.time).toFormat("HH:mm")}
                        </div>
                        <div>
                          {t("translation:runway")}: {landing.runway}
                        </div>
                      </Popup>
                    </GeoJSON>
                  );
                } else {
                  return null;
                }
              })}
            </FeatureGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay name={t("translation:takeoffs")} checked>
            <FeatureGroup pathOptions={takeoffPathStyleDefault}>
              {takeoffsGeoJson.map((feature, i) => {
                const takeoff = takeoffs[i];

                if (
                  takeoffs_to_plot.map((etp) => etp.id).includes(takeoff.id)
                ) {
                  return (
                    <GeoJSON
                      key={takeoff.id.toString()}
                      attribution="NTE-Skyview"
                      data={feature}
                      style={() =>
                        chosePathStyle(
                          takeoffPathStyleDefault,
                          takeoff.flightId
                        )
                      }
                      eventHandlers={{
                        click: handleMouseClick,
                      }}
                    >
                      <Popup>
                        <h3>
                          {<FontAwesomeIcon icon={faPlaneDeparture} />}{" "}
                          {takeoff.callsign}{" "}
                        </h3>
                        <h6>
                          <ReactCountryFlag countryCode={takeoff.countryCode} />{" "}
                          {takeoff.airline}
                        </h6>
                        <div>
                          {t("replay:takeoff_time")}:{" "}
                          {DateTime.fromISO(takeoff.time).toFormat("HH:mm")}
                        </div>
                        <div>
                          {t("translation:runway")}: {takeoff.runway}
                        </div>
                      </Popup>
                    </GeoJSON>
                  );
                } else {
                  return null;
                }
              })}
            </FeatureGroup>
          </LayersControl.Overlay>

          <RangeCircles />
          <Runways />
          <Pgs2019 />
        </LayersControl>
      </MapContainer>
    </div>
  );
}

export default forwardRef(FlightPath);
