import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import HttpApi from 'i18next-http-backend';

// https://www.robinwieruch.de/react-internationalization/
// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
// https://github.com/i18next/i18next-http-backend
// https://www.i18next.com/overview/configuration-options
i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
    {
        fallbackLng: 'en-GB',
        supportedLngs: ['en-GB', 'fr-FR'],
    
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
          },
    });


export default i18n;